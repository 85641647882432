import { OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgRegistrationSuccessMessage__client__getSuccessMessagesForOrgSubscription(p: { orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return firestoreLiftQuerySubToBifrostSub(
    h.OrgRegistrationSuccessMessage.querySubscription({
      where: [{ orgId: ["==", p.orgId] }]
    })
  );
}
