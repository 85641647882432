import { OrgCoupon, OrgRegistrationPackage, OrgRegistrationSuccessMessage } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function orgRegistrationSuccessMessage__client__addSuccessMessage(p: {
  successMessage: DistributiveOmit<OrgRegistrationSuccessMessage, "id" | "createdAtMS">;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const id = h.OrgRegistrationSuccessMessage.generateId();

  await h.OrgRegistrationSuccessMessage.add({
    doc: { ...p.successMessage, id, createdAtMS: Date.now() }
  });

  return id;
}
