import { OrgId, OrgRegistrationSuccessMessageId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { BatchTask } from "@ollie-sports/firebase";

export async function orgRegistrationSuccessMessage__client__deleteSuccessMessage(p: {
  orgRegistrationSuccessMessageId: OrgRegistrationSuccessMessageId;
  orgId: OrgId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const orgRegistrationPackages = (
    await h.OrgRegistrationPackage.query({
      where: [{ successMessageId: ["==", p.orgRegistrationSuccessMessageId] }, { orgId: ["==", p.orgId] }]
    })
  ).docs;

  const batchTasks: BatchTask[] = [];

  for (let i = 0; i < orgRegistrationPackages.length; i++) {
    batchTasks.push(
      await h.OrgRegistrationPackage.update(
        { id: orgRegistrationPackages[i].id, doc: { successMessageId: h._MagicDeleteValue } },
        { returnBatchTask: true }
      )
    );
  }
  batchTasks.push(
    await h.OrgRegistrationSuccessMessage.delete({ id: p.orgRegistrationSuccessMessageId }, { returnBatchTask: true })
  );

  await h._BatchRunner.executeBatch(batchTasks);
}
