import { OrgCoupon, OrgRegistrationPackage, OrgRegistrationSuccessMessage } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function orgRegistrationSuccessMessage__client__updateSuccessMessage(p: {
  successMessage: OrgRegistrationSuccessMessage;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.OrgRegistrationSuccessMessage.update({
    id: p.successMessage.id,
    doc: p.successMessage
  });
}
